import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncryptionService } from 'core/services/encryption.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private encryptionService: EncryptionService ,  private router: Router, private authService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const activeUser: any = localStorage.getItem("activeUser") ? JSON.parse(this.encryptionService.decrypt(localStorage.getItem("activeUser"))) : {};
    const accesssToken = localStorage.getItem("accesssToken");
    if (accesssToken && activeUser.access_token) {
      let tokenData = JSON.parse(atob(activeUser.access_token.split('.')[1]));
      let currentTime = Math.floor(Date.now() / 1000)
      let expireTime = tokenData.exp
      if (currentTime >= expireTime) {
        
        this.authService.fnLogout();
          this.router.navigate(['/login']);                    
          return null;
      }else{
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${activeUser.access_token}`
          }
        });
      }
    }

    return next.handle(request);
  }
}