import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboards',
    enable_menu: true,
    displayMenu: true
  },
  {
    label: 'Users',
    icon: 'grid',
    link: '/users',
    enable_menu: false,
    displayMenu: true
  },
  {
    label: 'Analytics',
    icon: 'trello',
    link: '/analytics',
    enable_menu: false,
    displayMenu: true
  }
];
