import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CanActivateFn, CanActivateChildFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EncryptionService } from '../../services/encryption.service';
import { AuthenticationService } from '../../services/authentication.service';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const encryptionService = inject(EncryptionService);
  const authService = inject(AuthenticationService);

  const isLoggedin = localStorage.getItem('isLoggedin');
  const activeUser = localStorage.getItem('activeUser');
  const accessToken = localStorage.getItem('accesssToken');

  if (isLoggedin && activeUser && accessToken) {
    const userData = JSON.parse(encryptionService.decrypt(activeUser));
    if (userData && userData.account_lock === true) {
      // Logged in but account_lock is true, so redirect to account-verification
      return of(router.createUrlTree(['/account-verification']));
    } else {
      // Logged in and account_lock is false, so allow access
      return of(true);
    }
  }
  authService.fnLogout();
  // Not logged in, so redirect to login page with the return url
  const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
  return of(router.createUrlTree(['/login'], { queryParams: { returnUrl: state.url } }));
};
