import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { environment } from '../environments/environment';

export function createApollo(httpLink: HttpLink) {
    const http = httpLink.create({ uri: environment.API_URL, method: 'POST' });
    const error = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            if (graphQLErrors[0].message === 'Not Authorised!') {
                localStorage.clear();
                window.location.href = window.location.origin;
            }
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const link = error.concat(http);
    return {
        link,
        cache: new InMemoryCache({ addTypename: false }),
    };
}

export const GraphQLModule = {
    provide: APOLLO_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink],
};
