import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { authLoginGuard } from 'core/auth/guards/authlogin.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboards' },
    {
        path: '',
        canActivate: [authLoginGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'login',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.routes'),
                canActivate: [authLoginGuard],
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.routes'),
                canActivate: [authLoginGuard],
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'classic',
        },
        children: [
            {
                path: 'dashboards',
                loadChildren: () =>
                    import(
                        'app/modules/admin/dashboards/dashboards.routes'
                    ),
            },
            {
                path: 'users',
                loadChildren: () =>
                    import('app/modules/admin/users/users.routes'),
            },
            {
                path: 'analytics',
                loadChildren: () =>
                    import('app/modules/admin/analytics/analytics.routes'),
            },
            { path: '', redirectTo: 'dashboards', pathMatch: 'full' },
            { path: '**', redirectTo: 'dashboards', pathMatch: 'full' },
        ],
    },
    { path: '', redirectTo: 'dashboards', pathMatch: 'full' },
    { path: '**', redirectTo: 'dashboards', pathMatch: 'full' },
];
