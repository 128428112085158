import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EncryptionService } from './encryption.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private userProfileSubject: BehaviorSubject<any>;
  public userProfile: Observable<any>;
  private profileObjectSubject: BehaviorSubject<any>;
  public profileObject: Observable<any>;

  constructor(
    private encryptionService: EncryptionService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>({});
    this.currentUser = this.currentUserSubject.asObservable();
    const userData: any = localStorage.getItem('activeUser') ? JSON.parse(this.encryptionService.decrypt(localStorage.getItem('activeUser'))) : null;
    if (userData) {
      this.currentUserSubject.next(userData);
    }
    this.userProfileSubject = new BehaviorSubject<any>({});
    this.userProfile = this.userProfileSubject.asObservable();
    this.profileObjectSubject = new BehaviorSubject<any>({});
    this.profileObject = this.profileObjectSubject.asObservable();
  }

  public get activeUserData() {
    return this.currentUserSubject.value;
  }

  storeProfileObject(data) {
    this.profileObjectSubject.next(data);
  }

  getProfileObject(): Observable<any> {
    return this.profileObjectSubject.asObservable();
  }

  getProfileData(): Observable<any> {
    return this.userProfileSubject.asObservable();
  }

  storeUserData(data: any) {
    const access_tokeId = data?.data?.access_token;
    localStorage.setItem("isLoggedin", "true");
    localStorage.setItem('accesssToken', access_tokeId);
    localStorage.setItem('activeUser', this.encryptionService.encrypt(JSON.stringify(data?.data)));
    localStorage.setItem('partner_id', data?.data?._id);
    this.currentUserSubject.next(data?.data);
  }

  storeProfileData(data) {
    this.userProfileSubject.next(data);
    this.storeProfileObject(data);
  }

  fnLogout() {
    localStorage.removeItem('accesssToken');
    localStorage.removeItem('activeUser');
    localStorage.removeItem('isSkipped');
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('rp');
    localStorage.removeItem('cuuid');
    localStorage.removeItem('s_t');
    localStorage.removeItem('verify_acc');
    localStorage.removeItem('partner_id');
    this.userProfileSubject.next(null);
  }
}