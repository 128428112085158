import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthenticationService } from 'core/services/authentication.service';
import { EncryptionService } from 'core/services/encryption.service';
import { Router } from '@angular/router';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent
{
    constructor(private authService: AuthenticationService, public encryptionService: EncryptionService,
        private router: Router
    )
    {
        const activeUser: any = localStorage.getItem("activeUser") ? JSON.parse(this.encryptionService.decrypt(localStorage.getItem("activeUser"))) : {};
        if (activeUser && "access_token" in activeUser) {
            let tokenData = JSON.parse(atob(activeUser.access_token.split('.')[1]));
            let currentTime = Math.floor(Date.now() / 1000)
            let expireTime = tokenData.exp
            if (currentTime >= expireTime) {
                this.authService.fnLogout();
                this.router.navigate(['/login']);
                return;
            }
        }
    }
    
}
