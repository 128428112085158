import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { Router, RouterModule, RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { Subscription } from 'rxjs';
import { MENU } from './menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'core/services/authentication.service';
import { Apollo } from 'apollo-angular';
import { partnerDetail } from 'core/services/graphql/auth/auth.query';
import { MatMenuModule } from '@angular/material/menu';

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        NgIf,
        NgFor,
        MatIconModule,
        MatButtonModule,
        RouterOutlet,
        RouterModule,
        NgbModule,
        NgClass,
        MatMenuModule,
        MatDividerModule
    ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    menuItems: any = MENU;
    isLoading: boolean;
    profileSubscription: Subscription;
    partnerProfile: any = {};

    constructor(
        private _router: Router,
        private authService: AuthenticationService,
        private apollo: Apollo
    ) {
        this.getProfileDetails();
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    async ngOnInit() {}

    async getProfileDetails(): Promise<void> {
        const partner_id = localStorage.getItem('partner_id');
        try {
            this.profileSubscription = this.apollo
                .query({
                    fetchPolicy: 'network-only',
                    query: partnerDetail,
                    variables: { _id: partner_id },
                })
                .subscribe((result: any) => {
                    this.partnerProfile = result['data'].partnerDetail.data;
                    this.authService.storeProfileData(this.partnerProfile);
                });
        } catch (error) {
            console.error('Error fetching profile details', error);
        }
    }

    signOut(): void {
        this.authService.storeProfileData(null);
        this.authService.storeUserData(null);
        this.authService.fnLogout();
        this._router.navigate(['/login']);
    }

    ngOnDestroy(): void {
        this.profileSubscription.unsubscribe();
    }
}
