import { Injectable } from '@angular/core';
import { Router, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';

export const authLoginGuard: CanActivateFn = () => {
    const router = inject(Router);
  
    if (!localStorage.getItem("activeUser") || !localStorage.getItem("accesssToken")) {
        return true;
    }
  
    router.navigate(['/dashboard/projects']);
    return false;
  };
  
