import gql from 'graphql-tag';

const partnerLogin = gql`
    query partnerLogin($username: String!, $password: String!) {
      partnerLogin(username: $username, password: $password) {
        status,
        message,
        data {
          _id,
          name,
          username,
          password,
          logo_rct,
          logo_mob,
          signup_url,
          unique_id_toggle,
          unique_id_validation_type,
          unique_id_username_min_length,
          unique_id_username_max_length,
          selected_subscription_plan_id,
          monthly_send_limit,
          unlock_post_included,
          url_blacklist,
          custom_description_toggle,
          custom_description,
          timezone,
          date_format,
          access_token
        }
      }
    }
`;

const partnerDetail = gql`
  query partnerDetail($_id: String) {
    partnerDetail(_id: $_id) {
      status
      message
      data {
        _id
        name
        username
        password
        logo_rct
        logo_mob
        signup_url
        unique_id_toggle
        unique_id_name
        unique_id_validation_type
        unique_id_prefix
        unique_id_username_min_length
        unique_id_username_max_length
        selected_subscription_plan_id
        monthly_send_limit
        unlock_post_included        
        url_blacklist
        custom_description_toggle
        custom_description
        timezone
        date_format
      }
    }
  }
`;

export { partnerLogin, partnerDetail };
